 <template>
  <div class="coin container">
    <div class="title">{{$t('app.cooperation.love')}}</div>
    <div class="box">
      <div class="earnings">
        <div>
          <router-link :to="{path: '/earningsRecord'}"><p>{{$t('app.cooperation.income')}}</p></router-link>
          <span>{{releasedByDay | numFilter4}} USDT</span>
        </div>
        <div>
          <p>{{$t('app.cooperation.total')}}</p>
          <span>{{released | numFilter4}} USDT</span>
        </div>
        <div class="btn" @click="returnsReceive">{{$t('app.cooperation.receive')}}</div>
      </div>
      <div class="investment">
        <h3>{{$t('app.cooperation.invest')}}</h3>
        <input type="text" v-model="val">
        <div><span>{{$t('app.cooperation.Balance')}}:</span> {{balance | numFilter4}} USDT</div>
      </div>
      <!-- <div class="btn" @click="investment" v-if="allowance>0">
        {{$t('app.cooperation.invest')}}
      </div>
      <div class="btn" @click="approve" v-else>
        {{$t('app.cooperation.Authorize')}}
      </div> -->
      <div class="withdraw">
        <router-link :to="{path: '/withdraw'}">{{$t('app.cooperation.history')}}></router-link>
      </div>
      <div class="quota">
        <div>
          <p>{{$t('app.cooperation.personal')}}：</p>
          <div><span>{{personage.allInvest}}</span> / <span>{{personage.canSwapByAddress}}</span>USDT</div>
        </div>
        <!-- <div>
          <p>{{$t('app.cooperation.all')}}：</p>
          <div><span>{{all.haveSwapByAll}}</span> / <span>{{all.canSwapByAll}}</span>USDT</div>
        </div> -->
      </div>
    </div>
    <div class="promotion">
      <h3>{{$t('app.cooperation.promote')}}</h3>
      <div>
        <p>{{$t('app.cooperation.Link')}} :</p>
        <div>http://welfare <span @click="Copy">{{$t('app.cooperation.copy')}}</span></div>
      </div>
      <div>
        <p>{{$t('app.cooperation.grade')}} :</p>
        <div v-if="level == 0">{{$t('app.cooperation.none')}}</div>
        <div v-else-if="level == 1">{{$t('app.cooperation.primary')}}</div>
        <div v-else-if="level == 2">{{$t('app.cooperation.intermediate')}}</div>
        <div v-else>{{$t('app.cooperation.advanced')}}</div>
      </div>
      <div>
        <p>{{$t('app.cooperation.award')}} :</p>
        <div>{{bindReward | numFilter4}} USDT <span @click="awardCollectionInvitation">{{$t('app.cooperation.receive')}}</span></div>
      </div>
      <div>
        <p>{{$t('app.cooperation.tzjl')}} :</p>
        <div>{{investment1 | numFilter4}} USDT <span @click="InvestmentReward">{{$t('app.cooperation.receive')}}</span></div>
      </div>
      <div>
        <p>{{$t('app.cooperation.performance')}} :</p>
        <div>{{achievement | numFilter4}} USDT</div>
      </div>
      <!-- <div>
        <p>社区代理等级 :</p>
        <div>v1</div>
      </div>
      <div>
        <p>社区收益 :</p>
        <div>1000USDT <span>领取</span></div>
      </div> -->
    </div>
    <div class="list">
      <p>{{$t('app.cooperation.mine')}} :</p>
      <div>
        <div>
          <span>{{$t('app.cooperation.address')}}</span> <span>{{$t('app.cooperation.yj')}}</span>
        </div>
        <div v-for="(item, index) in getSubordinates" :key="index">
          <span>{{item.address | addressCharacter(6,6)}}</span>
          <span>{{item.award}}</span>
        </div>
      </div>
    </div>
    <div class="pagination">
      <div class="pre" @click="front">{{$t('app.cooperation.pre')}}</div>
      <div class="page"><span>{{pageData.currentPage}}</span> / {{totalPage}}</div>
      <div class="next" @click="broughtForward">{{$t('app.cooperation.next')}}</div>
    </div>
  </div>
</template>

<script>
// import AutoInput from '../../components/autoInput.vue'
import { ethers } from 'ethers'
import Community from '@/contracts/Community.json'
import ERC20 from '../../contracts/ERC20.json'
import { initSigner } from '../../utlis/index'
import Help from "../../contracts/Help.json"
import ReleasedByDay from "../../contracts/ReleasedByDay.json"
import Bind from '../../contracts/Bind.json'
export default {
  data() {
    return {
      contracts: {},
      address: '',
      referee: '',
      released: 0, //总收益
      releasedByDay: 0, //昨日收益
      balance: 0,//余额
      allowance: 0, //授权额度
      val: '',//投资金额
      personage: {
        allInvest: 0,
        canSwapByAddress: 0,
      },
      all: {
        canSwapByAll: 0,
        haveSwapByAll: 0,
      },
      bindReward: 0, //邀请奖励
      achievement: 0,
      list: {
        address: [],
        award: [],
      },
      getSubordinates: [],
      pageData: {
        currentPage: 1,
        pageSize: 5,
        total: 0
      },
      level:'',
      investment1:0,
    }
  },
  watch: {

  },
  computed: {
    coinNumber() {
      let num = '-'
      if (this.UNumber) {
        num = Number(this.UNumber) / 150
      }
      return num
    },
    startIndex() {
      return (this.pageData.currentPage - 1) * this.pageData.pageSize
    },
    totalPage() {
      return Math.ceil(this.pageData.total / this.pageData.pageSize)
    },
    // 邀请地址
    url() {
      if (!this.address) return '-'
      let url = location.origin + '?referee=' + this.address
      return url
    },
  },
  mounted() {
    this.referee = this.$route.query.referee || '0x0000000000000000000000000000000000000000'
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  methods: {
    async init() {
      let { address, provider, signer } = await initSigner()
      this.address = address
      this.contracts.Community = new ethers.Contract(this.$common.Community, Community, provider).connect(signer)
      this.contracts.Help = new ethers.Contract(this.$common.Help, Help, provider).connect(signer)
      this.contracts.ReleasedByDay = new ethers.Contract(this.$common.ReleasedByDay, ReleasedByDay, provider).connect(signer)
      this.contracts.USDT = new ethers.Contract(this.$common.USDT, ERC20, provider).connect(signer)
      this.contracts.Bind = new ethers.Contract(this.$common.Bind, Bind, provider).connect(signer)
      window.contracts = this.contracts;
      this.getData()
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async getData() {
      if (!this.address) return false
      // 总收益
      this.contracts.Help.released(this.address).then(res => {
        let released = ethers.utils.formatUnits(res, 18)
        this.released = released
      })
      //等级
      this.contracts.Help.level(this.address).then(res => {
        let level = ethers.utils.formatUnits(res, 0)
        this.level = level
      })
      //昨日收益
      this.contracts.ReleasedByDay.releasedByDay(this.address).then(res => {
        let releasedByDay = ethers.utils.formatUnits(res, 18)
        this.releasedByDay = releasedByDay
      })
      //余额
      this.contracts.USDT.balanceOf(this.address).then(res => {
        let balance = ethers.utils.formatUnits(res, 18)
        this.balance = balance
      })
      //个人投资限额度
      this.contracts.Help.allInvest(this.address).then(res => {
        let allInvest = ethers.utils.formatUnits(res, 18)
        this.personage.allInvest = allInvest
      })
      this.contracts.Help.canSwapByAddress().then(res => {
        let canSwapByAddress = ethers.utils.formatUnits(res, 18)
        this.personage.canSwapByAddress = canSwapByAddress
      })
      //全部投资限额
      this.contracts.Help.haveSwapByAll().then(res => {
        let haveSwapByAll = ethers.utils.formatUnits(res, 18)
        this.all.haveSwapByAll = haveSwapByAll
        // console.log(this.all.haveSwapByAll);
      })
      this.contracts.Help.canSwapByAll().then(res => {
        let canSwapByAll = ethers.utils.formatUnits(res, 18)
        this.all.canSwapByAll = canSwapByAll
        // console.log(this.all.canSwapByAll);
      })
      //授权额度
      this.contracts.USDT.allowance(this.address, this.$common.Help).then(res => {
        let num = ethers.utils.formatUnits(res, 18)
        this.allowance = num
      })
      //推广奖励
      this.contracts.Help.bindReward(this.address).then(res => {
        let bindReward = ethers.utils.formatUnits(res, 18)
        this.bindReward = bindReward
      })
      //总推广奖励
      this.contracts.Help.achievement(this.address).then(res => {
        let achievement = ethers.utils.formatUnits(res, 18)
        this.achievement = achievement
      })
      //投资奖励
      this.contracts.Help.bindInvestReward(this.address).then(res => {
        let investment1 = ethers.utils.formatUnits(res, 18)
        this.investment1 = investment1
        // console.log(this.investment1,'投资奖励');
      })
      //总条数
      this.contracts.Bind.userMap(this.address).then(res => {
        this.pageData.total = ethers.utils.formatUnits(res.subNum, 0)
      })
      this.Pagination()
    },
    Pagination() {
      //下级地址
      this.contracts.Help.getSubordinateReward(this.address, this.startIndex, this.pageData.pageSize).then(res => {
        this.getSubordinates = []
        res[0].forEach((item, index) => {
          this.$set(this.getSubordinates, index, {
            address: item,
            award: ethers.utils.formatUnits(res[1][index], 18),
          })
        })
      })
    },
    //复制
    Copy() {
      let input = document.createElement("input");
      input.value = this.url;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message.success(this.$t('app.cooperation.copyCG'))
    },
    //收益领取
    returnsReceive() {
      if (this.released <= 0) return this.$message.error(this.$t('app.cooperation.No'))
      this.contracts.Help.getReleased().then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.receiveCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.receiveSB'))
      });
    },
    // 授权USDT
    approve() {
      const max = ethers.constants.MaxUint256
      this.contracts.USDT.approve(this.$common.Help, max).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.approveCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.approveSB'))
      });
    },
    // 投资
    investment() {
      // console.log(111);
      // console.log(Number(this.all.haveSwapByAll) >= Number(this.all.canSwapByAll));
      if(Number(this.all.haveSwapByAll) >= Number(this.all.canSwapByAll)) return  this.$message.error(this.$t('app.cooperation.investSB'))
      const val = ethers.utils.parseUnits(this.val, 18)
      this.contracts.Help.swap(this.referee || '0x0000000000000000000000000000000000000000', val).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.investCG'))
        this.val = ''
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.investSB'))
      });
    },
    //领取邀请奖励
    awardCollectionInvitation() {
      if (this.bindReward <= 0) return this.$message.error(this.$t('app.cooperation.No'))
      this.contracts.Help.getBindReward().then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.receiveCG'))
        this.val = ''
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.receiveSB'))
      });
    },
    //领取投资奖励
    InvestmentReward(){
      if (this.investment1 <= 0) return this.$message.error(this.$t('app.cooperation.No'))
      this.contracts.Help.getBindInvestReward().then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.receiveCG'))
        this.val = ''
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.receiveSB'))
      });
    },
    //分页
    front() {
      if (this.pageData.currentPage > 1) {
        this.pageData.currentPage--
        this.Pagination()
      }
    },
    broughtForward() {
      if (this.pageData.currentPage < this.totalPage) {
        this.pageData.currentPage++
        this.Pagination()
      }
    },
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.coin {
  padding: 1.4rem 0.44rem 0.4rem;
  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url("../../assets/img/web-title-bg.png") left top / 100% 100%
      no-repeat;
  }
  // .border {
  //   border: 1px solid #BBB;
  // }
  .box {
    padding: 0.6rem;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 50px;
    background: url("../../assets/img/coin-bg.png") left top / 100% 100%
      no-repeat;
    .earnings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.363);
      .btn {
        width: 60px;
        height: 30px;
        border-radius: 6px;
        line-height: 30px;
        background-color: rgba(178, 133, 237, 0.8);
      }
    }
    .investment {
      margin-top: 30px;
      margin-bottom: 20px;
      h3 {
        text-align: left;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 400;
      }
      input {
        width: 100%;
        height: 40px;
        background-color: rgba(85, 114, 241, 0.8);
        border: 1px solid #45bbf3;
        margin-bottom: 5px;
        color: #fff;
      }
      > div {
        text-align: right;
      }
    }
    > .btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      background-color: rgba(178, 133, 237, 0.8);
      border: 1px solid #45bbf3;
      margin-bottom: 10px;
    }
    .quota {
      margin-top: 20px;
      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
    }
  }
  .promotion {
    h3 {
      text-align: center;
      margin-bottom: 25px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      > div {
        span {
          display: inline-block;
          width: 60px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          background-color: rgba(178, 133, 237, 0.8);
          border-radius: 6px;
          margin-left: 5px;
        }
      }
    }
  }
  .list {
    p {
      margin-bottom: 20px;
    }
    > div {
      > div {
        margin-bottom: 10px;
        span {
          display: inline-block;
          width: 49%;
          text-align: center;
        }
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.3rem;
    margin: 0.3rem 0 0.6rem;
    .page {
      width: 2rem;
      text-align: center;
      span {
        color: #45bbf3;
      }
    }
    .pre,
    .next {
      cursor: pointer;
      &:hover {
        color: #45bbf3;
      }
    }
  }
}
</style>
